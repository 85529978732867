import { appInfo as karrotAppInfo } from './bridge/appInfo';

export const getAppVersion = () => {
  const userAgent = karrotAppInfo.getData()?.app.userAgent ?? '';
  const [appInfo, osInfo] = userAgent.split(' ');
  const [, , version] = appInfo.split('/');
  const [os] = (osInfo || 'iOS').split('/');

  return { os, version: +version };
};

export const isHapticWorking = () => {
  const { version, os } = getAppVersion();
  if (os === 'iOS') {
    if (version >= 68300) {
      return true;
    }
  } else {
    if (version >= 64900) {
      return true;
    }
  }
  return false;
};
