import { makeStorage } from '../../storage';
import { bridge } from '../index';
import { AppInfo, KarrotHeader, mockAppInfo } from './type';
import { isTowneersWebview } from '@utils/webview';

export const appInfo = makeStorage<AppInfo>(mockAppInfo);

export const initAppInfo = async () => {
  if (typeof window === 'undefined' || !isTowneersWebview()) {
    appInfo.setData(mockAppInfo);
    return true;
  }

  const [deviceInfo, regionInfo, userInfo] = await Promise.all([
    bridge?.getAppInfo({}),
    bridge?.getRegionInfo({}),
    bridge?.getUserInfo({}),
  ]);

  const currentAppInfo = {
    app: deviceInfo?.info.app,
    region: regionInfo?.info.region,
    user: userInfo?.info.user,
  } as AppInfo;

  appInfo.setData(currentAppInfo);

  return true;
};

export const getKarrotCustomHeader = (): KarrotHeader => ({
  'X-Auth-Token': appInfo.getData()?.user.authToken,
  'X-User-Id': appInfo.getData()?.user.id.toString(),
  'X-Region-Id': appInfo.getData()?.region.id.toString(),
  'X-User-Agent': appInfo.getData()?.app.userAgent,
});

export const getXPropertyCustomHeader = (): KarrotHeader => ({
  'X-Auth-Token': appInfo.getData()?.user.authToken,
});
