import React, { ReactNode, useEffect, useState } from 'react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import styled from 'styled-components';
import { vars } from '@seed-design/design-token';
import CircularProgress from './common/CircularProgress';
import { useInitVConsole } from '@utils/vconsole';
import { initAmplitudeHandler } from '@hooks/useInitAmplitude';
import { initAppInfo } from '@utils/bridge/appInfo';
import { isKakaoTalkWebview } from '@utils/webview';
import { BottomSheetProvider } from '@src/providers/BottomSheet';
import { ModalProvider } from '@src/providers/Modal';
import { SnackbarProvider } from '@daangn/sprout-components-snackbar';
import '@daangn/sprout-components-snackbar/index.css';

const queryClient = new QueryClient();

const RootElement = ({ children }: { children: ReactNode }) => {
  const [loading, setLoading] = useState(isKakaoTalkWebview() ? false : true);
  useInitVConsole();

  // bridge에서 appInfo를 init
  const initApp = async () => {
    await initAppInfo();
    initAmplitudeHandler();
    setLoading(false);
  };
  useEffect(() => {
    initApp();
  }, []);

  return loading ? (
    <Wrapper>
      <CircularProgress />
    </Wrapper>
  ) : (
    <QueryClientProvider client={queryClient}>
      <SnackbarProvider offset="100px">
        <BottomSheetProvider>
          <ModalProvider>{children}</ModalProvider>
        </BottomSheetProvider>
      </SnackbarProvider>
    </QueryClientProvider>
  );
};

export default RootElement;

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  min-height: 90vh;
  background-color: ${vars.$semantic.color.paperDefault};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;
