import {
  initAmplitude,
  initAmplitudeAnonymous,
  setAdditionalUserDataToAmplitude,
  setInitialUserDataToAmplitude,
} from '@utils/analytics/amplitude';
import { appInfo } from '@utils/bridge/appInfo';

import { isTowneersWebview } from '@utils/webview';
import { useEffect } from 'react';

export const initAmplitudeHandler = async (afterInitCallback?: (info?: any) => void) => {
  if (typeof window === 'undefined' || !isTowneersWebview()) {
    await initAmplitudeAnonymous();
    afterInitCallback?.();
    return;
  }

  await initAmplitude(async () => {
    await setInitialUserDataToAmplitude();
    await setAdditionalUserDataToAmplitude();

    const userInfo = appInfo.getData()?.user;
    afterInitCallback?.(userInfo);
  });
};

const useInitAmplitude = (afterInitCallback?: (info?: any) => void) => {
  useEffect(() => {
    initAmplitudeHandler(afterInitCallback);
  }, []);
};

export default useInitAmplitude;
