import { mockAppInfo } from './bridge/appInfo/type';

export const makeStorage = <T>(initValue?: T) => {
  let data = initValue;

  return {
    setData: (nextData: T): void => {
      data = nextData;
    },
    getData: (): T => {
      if (!data) {
        return mockAppInfo as unknown as T;
        // throw new Error(`makeStorage is not initialized`);
      }

      return data;
    },
  };
};
