import { useEffect, useRef } from 'react';
interface useModalStateProps {
  isModalOpen: boolean;
  setModalClose: () => void;
}
/**
 * 안드로이드 back 버튼 history 대응
 * @param props
 */
export const useModalState = (props: useModalStateProps) => {
  const { isModalOpen } = props;
  const prevPath = useRef('');
  useEffect(() => {
    if (!isModalOpen) {
      return;
    }
    const hashUrlRegex = new RegExp('#(\\/.*?)(\\?.*)?$', 'g');
    const [, path] = hashUrlRegex.exec(window.location.href) ?? [];
    prevPath.current = path;
    window.history.pushState({}, '');
  }, [isModalOpen]);
  useEffect(() => {
    if (!isModalOpen) {
      return;
    }
    // android 백버튼 또는 브라우저 백버튼 눌렀을 때
    const onPopState = (event: any) => {
      const hashUrlRegex = new RegExp('#(\\/.*?)(\\?.*)?$', 'g');
      const [, path] = hashUrlRegex.exec(event.target.location.href) ?? [];
      if (prevPath.current !== path) {
        prevPath.current = path;
        return;
      }
      props.setModalClose();
    };
    window.addEventListener('popstate', onPopState);
    return () => window.removeEventListener('popstate', onPopState);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isModalOpen]);
  const handleModalClose = () => {
    window.history.back();
    props.setModalClose();
  };
  return { isModalOpen, setModalClose: handleModalClose };
};
