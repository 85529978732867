import { installKarrotBridgeDriver, makeKarrotBridge } from '@daangn/karrotbridge/lib';

import { isHapticWorking } from '@utils/version';
import { isTowneersWebview } from '@utils/webview';

export const bridge = (() => {
  if (typeof window === 'undefined') return undefined;
  // 당근웹뷰가 아니라면 bridge를 사용하지 않는다.
  // kakaoTalk 인앱 웹뷰 이슈
  if (!isTowneersWebview()) return undefined;

  const { driver } = installKarrotBridgeDriver();

  const karrotBridge = makeKarrotBridge({
    driver,
  });

  return {
    ...karrotBridge,
    setHapticSuccess: (req: object) =>
      isHapticWorking() ? karrotBridge.setHapticSuccess(req) : Promise.resolve({}),
    setHapticError: (req: object) =>
      isHapticWorking() ? karrotBridge.setHapticError(req) : Promise.resolve({}),
    setHapticSelect: (req: object) =>
      isHapticWorking() ? karrotBridge.setHapticSelect(req) : Promise.resolve({}),
    setHapticLightFeedback: (req: object) =>
      isHapticWorking() ? karrotBridge.setHapticLightFeedback(req) : Promise.resolve({}),
  };
})();

type StyleCurrentRouterParams = {
  scrollable?: boolean;
  backSwipable?: boolean;
  IOS_ONLY_hideKeyboardAccessoryView?: boolean;
};
export const updateStyleCurrentRouter = ({
  scrollable = false,
  backSwipable = false,
  IOS_ONLY_hideKeyboardAccessoryView = true,
}: StyleCurrentRouterParams) => {
  bridge?.styleCurrentRouter({
    router: {
      // noti로 접근하는 경우 & 기존 페이지에서 접근하는 경우
      backSwipable,
      navbar: false,
      scrollable,
      enableSafeAreaInsets: false,
      IOS_ONLY_hideKeyboardAccessoryView,
    },
  });
};
