export const isKakaoTalkWebview = () => {
  if (typeof window === 'undefined') return false;
  const userAgent = window.navigator.userAgent;
  return userAgent.includes('KAKAOTALK') || userAgent.includes('kakao');
};

export const isTowneersWebview = () => {
  if (typeof window === 'undefined') return false;
  const userAgent = window.navigator.userAgent;
  return userAgent.includes('Towneers');
};
