import { AnimatePresence } from 'framer-motion';
import React, {
  createContext,
  MouseEventHandler,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Modal } from '../../components/common/Modal';
import { Overlay } from '../../components/common/Overlay';
import { useModalState } from '../../hooks/useModalState';
import { bridge } from '@utils/bridge/index';

interface ModalOptions {
  element: ReactNode;
  config?: {
    dimAnimationDuration?: number;
    bottomSheetAnimationDuration?: number;
    isDimAnimation?: boolean;
  };
  onOpened?: () => void;
  onClosed?: () => void;
  onDimClose?: MouseEventHandler<HTMLDivElement>;
}

interface ModalContextProps {
  open: (options: ModalOptions) => void;
  close: () => void;
  closeAsync: () => Promise<void>;
  setModalOptions: (options: ModalOptions) => void;
}

const ModalContext = createContext<ModalContextProps | undefined>(undefined);

export const ModalProvider = ({ children }: { children: ReactNode }) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [bottomSheetOptions, setModalOptions] = useState<ModalOptions>({
    element: <></>,
    config: {
      dimAnimationDuration: DIM_DURATION,
      bottomSheetAnimationDuration: BOTTOM_SHEET_DURATION,
      isDimAnimation: true,
    },
  });

  const { config } = bottomSheetOptions;

  const { isModalOpen, setModalClose } = useModalState({
    isModalOpen: isOpen,
    setModalClose: () => setIsOpen(false),
  });

  const isOpenRef = useRef(isOpen);

  const open = useCallback((options: ModalOptions) => {
    if (isOpenRef.current) {
      setModalClose();
    }
    bridge?.setHapticLightFeedback({});
    setModalOptions({ ...options });
    setIsOpen(true);
  }, []);

  const close = useCallback(() => {
    setModalClose();
  }, []);

  const closeAsync = useCallback(
    () =>
      new Promise<void>((resolve) => {
        setModalClose();

        setTimeout(() => resolve(undefined), CLOSE_DURATION);
      }),
    []
  );

  const controls = useMemo(
    () => ({ open, close, closeAsync, setModalOptions }),
    [open, close, closeAsync, setModalOptions]
  );

  const handleDimClose = (e: React.MouseEvent<HTMLDivElement>) => {
    if (bottomSheetOptions && bottomSheetOptions.onDimClose) {
      return bottomSheetOptions.onDimClose(e);
    }

    close();
  };

  useEffect(() => {
    isOpenRef.current = isModalOpen;
  }, [isModalOpen, isOpen]);

  return (
    <ModalContext.Provider value={controls}>
      {children}
      <AnimatePresence>
        {isOpen && (
          <Modal
            isOpen={Boolean(isOpen)}
            dimDuration={config?.bottomSheetAnimationDuration ?? BOTTOM_SHEET_DURATION}
          >
            {bottomSheetOptions.element}
          </Modal>
        )}
      </AnimatePresence>
      {config?.isDimAnimation ??
        (true && (
          <Overlay
            isOpen={Boolean(isOpen)}
            onDimClose={handleDimClose}
            dimDuration={config?.dimAnimationDuration ?? DIM_DURATION}
          />
        ))}
    </ModalContext.Provider>
  );
};

export const useModal = () => {
  const controls = useContext(ModalContext);

  if (controls === undefined) {
    throw new Error('ModalContext 안에서 사용해주세요.');
  }

  return controls;
};

const CLOSE_DURATION = 200;
const DIM_DURATION = 0.15;
const BOTTOM_SHEET_DURATION = 0.2;
