import { identify, Identify, init } from '@amplitude/analytics-browser';
import { track } from '@amplitude/analytics-browser';
import { setUserId } from '@amplitude/analytics-browser';
import { getUsersMeDemography } from '@src/apis/common';
import { appInfo } from '@utils/bridge/appInfo';
import { bridge } from '@utils/bridge/index';
import { v4 } from 'uuid';
import { GroupEventParams, SpecificEventType } from './type';

// import { oldBridge } from '../bridge';

type RegionType = {
  name1: string;
  name2: string;
  name3: string;
  name: string;
};

const AMPLITUDE_DEV_KEY = '84d71a58e9da0adf277cffcd6889d39a';
const AMPLITUDE_PRODUCTION_KEY = '434009e9442913f0611fca3780cacc38';
const AMPLITUDE_API_KEY =
  process.env.GATSBY_ENV === 'development' ? AMPLITUDE_DEV_KEY : AMPLITUDE_PRODUCTION_KEY;

export async function initAmplitude(callback?: () => void) {
  const env = await bridge?.driver.getCurrentEnvironment();
  if (env === 'Web') {
    await initAmplitudeAnonymous();
    return;
  }

  // const { info: appInfo } = await bridge?.getUserInfo({});
  const appInfoData = appInfo.getData()?.user;
  if (appInfoData?.id)
    await init(AMPLITUDE_API_KEY, appInfoData?.id.toString(), {
      attribution: {
        trackNewCampaigns: true,
      },
    });
  console.log('[[[[[Amplitude Initiated]]]]]');

  callback?.();
}

export async function initAmplitudeAnonymous(callback?: () => void) {
  await init(AMPLITUDE_API_KEY, 'anonymous', {
    attribution: {
      trackNewCampaigns: true,
    },
  });

  console.log('[[[[[Amplitude Initiated anonymous]]]]]');
  callback?.();
  return;
}

export async function setInitialUserDataToAmplitude() {
  const userInfo = appInfo.getData()?.user;
  const regionInfo = appInfo.getData()?.region;

  const userRegionData = regionInfo as RegionType;
  const userNativeData = userInfo;
  const indentifyObj = new Identify();
  //!!! setUserId 는 절대 절대 절대 바꾸지 마세요. 바꾸는 순간 모든 데이터 다 날라감 (참고: https://daangn.slack.com/archives/C0324MBCA9Z/p1662570661305459)
  setUserId('karrot-' + userNativeData?.id);

  indentifyObj.set('checkinCity', userRegionData.name1);
  indentifyObj.set('checkinGu', userRegionData.name2);
  indentifyObj.set('checkinDong', userRegionData.name3);
  indentifyObj.set('checkinMostAccurate', userRegionData.name);
  identify(indentifyObj);
}

export async function setAdditionalUserDataToAmplitude() {
  const { userGenderAgePresentation } = await getUsersMeDemography();

  const indentifyObj = new Identify();
  if (userGenderAgePresentation.ageRange) {
    // response data 형식(https://daangn.slack.com/archives/C0324MBCA9Z/p1663665460932799)
    const ageRangeMinMaxArr = userGenderAgePresentation.ageRange.split('-');
    const minAge = ageRangeMinMaxArr[0];
    const maxAge = ageRangeMinMaxArr[1] !== '' ? ageRangeMinMaxArr[1] : '60';
    indentifyObj.set('ageRange', {
      min: minAge,
      max: maxAge,
    });
    indentifyObj.set('ageRangeRaw', userGenderAgePresentation.ageRange);
  }
  identify(indentifyObj);
}

export function trackAmplitudeEvent(event: SpecificEventType, params?: GroupEventParams) {
  // Amplitude
  track(event, params);
}
