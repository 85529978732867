import { getKarrotCustomHeader } from '@utils/bridge/appInfo';
import axios from 'axios';

export const API_ENDPOINT =
  process.env.GATSBY_ENV === 'development'
    ? 'https://community-api.alpha.kr.karrotmarket.com'
    : 'https://community-api.kr.karrotmarket.com';

export const axiosInstanceParams = (header = {}) => {
  return {
    axios: axios.create({
      baseURL: API_ENDPOINT,
      timeout: 10000,
      headers: {
        Accept: 'application/json',
        ...getKarrotCustomHeader(),
        ...header,
      },
    }),
  };
};

export const fetchInstance = () => axiosInstanceParams();

export const handleAxiosError = (error: unknown) => {
  if (axios.isAxiosError(error)) {
    return error.response ?? { data: null };
  }

  throw error;
};

export const MOCK_TOKEN = 'b21cf28030314ed6102f92ae261bb6b6abcec5853b72a8c5debef1525f439243';
