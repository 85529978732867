import { useEffect } from 'react';
import { isKakaoTalkWebview } from './webview';

const vconsoleConfig = {
  onReady: () => {
    const vConsoleSwitch = document.querySelector('.vc-switch') as HTMLElement;
    vConsoleSwitch.style.position = 'fixed';
    vConsoleSwitch.style.top = '64px';
    vConsoleSwitch.style.height = '30px';
  },
};

export const initVConsole = async () => {
  if (process.env.GATSBY_ENV !== 'production') {
    const VConsole = (await import('vconsole')).default;

    const console = new VConsole(vconsoleConfig);
    console.setSwitchPosition(20, 20);
  }
};

export const useInitVConsole = () => {
  useEffect(() => {
    if (typeof window !== 'undefined') initVConsole();
  }, []);
};
