import { vars } from '@seed-design/design-token';
import { motion } from 'framer-motion';
import React from 'react';
import { ReactNode, useRef } from 'react';
import styled from 'styled-components';

interface Props {
  isOpen: boolean;
  children: ReactNode;
  dimDuration?: number;
}

export const Modal = ({ children, dimDuration = 0.2 }: Props) => {
  const ref = useRef<HTMLDivElement | null>(null);

  return (
    <Wrapper
      ref={ref}
      initial={{ height: 0 }}
      animate={{
        height: 'auto',
      }}
      transition={{
        type: 'spring',
        bounce: 0.1,
        duration: dimDuration,
      }}
      exit={{ height: 0 }}
    >
      {children}
    </Wrapper>
  );
};

const Wrapper = styled(motion.div)`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  z-index: 9999;
`;
