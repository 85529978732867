import { ReactNode, useEffect, useState } from 'react';
import ReactDOM from 'react-dom';

interface Props {
  children: ReactNode;
}

let container: Element | null = null;

export const Portal = ({ children }: Props) => {
  const [target] = useState(() => {
    if (typeof document === 'undefined') {
      return null;
    }

    if (!container) {
      container = document.createElement('div');
      document.body.appendChild(container);
    }

    const div = document.createElement('div');
    container.appendChild(div);

    return div;
  });

  useEffect(
    () => () => {
      if (target) {
        container?.removeChild(target);
      }
    },
    [target]
  );

  return target ? ReactDOM.createPortal(children, target) : null;
};
