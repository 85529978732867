import { vars } from '@seed-design/design-token';
import { forwardRef, MouseEventHandler } from 'react';

import { Portal } from '../Portal';
import { motion } from 'framer-motion';
import React from 'react';
import styled from 'styled-components';
import { useHideBody } from '../../../hooks/useHideBody';

interface Props {
  isOpen: boolean;
  onDimClose?: MouseEventHandler<HTMLDivElement>;
  dimDuration?: number;
}

export const Overlay = forwardRef<HTMLDivElement, Props>(
  ({ isOpen, onDimClose, dimDuration = 0.15 }, ref) => {
    const { bind } = useHideBody(isOpen);

    return isOpen ? (
      <Portal>
        <Wrapper {...bind} ref={ref}>
          <Dim
            initial={{ opacity: 0 }}
            animate={{
              opacity: 1,
            }}
            exit={{ opacity: 0 }}
            transition={{
              duration: dimDuration,
            }}
            onClick={(event: { preventDefault: () => void; stopPropagation: () => void }) => {
              event.preventDefault();
              event.stopPropagation();

              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              onDimClose?.();
            }}
          />
        </Wrapper>
      </Portal>
    ) : (
      <></>
    );
  }
);

const Wrapper = styled.div`
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  overflow: 'auto';
  width: 100vw;
  height: 100vh;
  z-index: 9990;
  position: fixed;
  content: "''";
  -webkit-tap-highlight-color: 'transparent';
`;

// TODO: background에 theme.colors.$gray900을 할 경우 다크모드에서 흰색으로 dim이 되어 어색함. 다크모드에서 dim 배경이 어떤 색으로 되는 것이 이상적인지 제임스와 이야기할 것
const Dim = styled(motion.div)`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: ${vars.$semantic.color.overlayDim};
`;
