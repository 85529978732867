import { KarrotBridgeSchema } from '@daangn/karrotbridge/lib/__generated__/makeKarrotBridge';

export declare type KarrotHeader = {
  'X-Auth-Token'?: string;
  'X-User-Id'?: string;
  'X-Region-Id'?: string;
  'X-User-Agent'?: string;
};

export type AppInfo =
  | {
      app: KarrotBridgeSchema['ReqInfoAppResponse']['info']['app'] & { appVersion: string };
      region: RegionInfo;
      user: KarrotBridgeSchema['ReqInfoUserResponse']['info']['user'] & {
        displayRegionCheckinsCount: number;
      };
    }
  | undefined;

type RegionInfo = {
  fullname: string;
  id: number;
  name1Id: number;
  name2Id: number;
  name3Id: number;
  name: string;
  name1: string;
  name2: string;
  name3: string;
  centerCoordinates: {
    longitude: number;
    latitude: number;
  };
};

export const mockAppInfo: AppInfo = {
  app: {
    locale: 'ko-KR',
    appVersion: '23.12.0',
    country: 'KR',
    appVersionCode: '231200',
    appHost: 'api.alpha.kr.karrotmarket.com',
    userAgent: 'TowneersApp/23.12.0/231200 iOS/16.1.0/1953.1 iPhone13,3',
    deviceIdentity: 'C65B6DF9-55B5-42CD-827E-25F85F34B079',
  },
  region: {
    fullname: '',
    id: 0,
    name1Id: 0,
    name2Id: 0,
    name3Id: 0,
    name: '',
    name1: '',
    name2: '',
    name3: '',
    centerCoordinates: {
      longitude: 0,
      latitude: 0,
    },
  },
  user: {
    id: 0,
    hashId: '',
    authToken: '',
    phone: '',
    nickname: '',
    profileImage: '',
    displayRegionCheckinsCount: 0,
  },
};
