import { AxiosResponse } from 'axios';
import { fetchInstance } from '.';

type UsersMeDemographyResponse = {
  userGenderAgePresentation: {
    userId: number;
    ageRange?: string;
    gender: 'GRAY' | 'MALE' | 'FEMALE';
  };
};

export const COMMON_URL = '/api/v1';

// 유저의 나이, 성별 정보를 랜딩해요.
export const getUsersMeDemography = async () => {
  const { data } = await fetchInstance().axios.get<
    undefined,
    AxiosResponse<UsersMeDemographyResponse>
  >(`${COMMON_URL}/users/me/demography`);

  return data;
};
